<template>
	<section>
		<div class="game__text">
			<h1 class="game__text-paragraf">If you are over 30 years old, we have about 350,000 hours (40 years) left!</h1>
			<br />
			<p class="game__text-paragraf">
				For 6 years our company Longevity InTime worked to extend for at least 100,000+ hours active lifespan. 
				<br />
				<a class="game__text-link" target="_blank" href="https://youtu.be/vN2yLmlUQsk?si=1tNn9aPi9Qjs-TZI"
					>To understand better, why it’s hard than ever, Leonardo DiCaprio & Jennifer Lawrence will explain</a
				>
				<br /><br />
				We tried several approaches to change people’s mindset towards longevity, fundamental science in life extension,
				personal health care & funding clinical trials.
				<br /><br />
				We came to conclusion, that the fastest way is to approach masses through launching Longevity Coin on TON
				network with a concept similar to mini app blockbuster <a class="game__text-link" target="_blank" href="https://t.me/hamster_kombat_bot"
                >Hamster Kombat</a>. We called it: Tap
				To Earn & Live Longer.
				<br /><br />
				It’s simple game with healthy / unhealthy / ridiculous / important cards (tasks), through which we educate
				people that self care is the most important in their lives.  
				<br /><br />
				We give 3 742 200 000 seconds (120 years of game life) which are equal 1 second of game life = 1 second of real
				life. Seconds are burning every second. User needs to explore healthy cards to add seconds. To get access to
				healthy cards user needs to open unhealthy cards. Unhealthy cards burn seconds much quicker. However healthy
				cards reinstate in someways the lost balance. These set of cards explain what should people do in real life to
				live longer and avoid severe consequences.
				<br /><br />
				Ridiculous & Important cards explain how the world’s masterminds, billionaires & presidents are careless. How
				they are busy to earn money, which won’t save their lives.
				<br />The game is a part of promotion campaign of Longevity Coin exchange listing. <br />
				The cut off date, when game achievements are frozen, will come unexpectedly in any moment, with clear exchange
				rate for converting seconds in to tokens, which can be exchanged in the top crypto exchanges.
				<br /><br />Longevity Coin differences from any other coins:<br />
				1. Backed by the real AI powered longevity technologies of the 6 year old company 
				<br />2.  Transparent founders & team  behind the company & coin<br />3. Clear exchange rate of the game coins
				into crypto coins<br />4. Users can chat and trade their achievements within the game  <br />5. Educational
				incentive in health care, wellness, lifespan extension<br />
				6. Access to the whole Longevity EcoSystem with Longevity Coin as a payment tool  
				<br />7. Many more
			</p>
		</div>
	</section>
</template>

<style lang="scss" scoped>
.game__text {
	background-image: url(../assets/img/svg/back_pattern.svg);
	background-size: cover;
	margin: 0 auto;
	// width: 85%;
	padding: 50px 100px;
	color: var(--masstrich-blue);
	font-style: normal;
	font-weight: 400;
	font-family: "Titillium Web";
	line-height: 2.8;
}

.game__text-paragraf {
	min-height: 100%;
	margin: 0;
	font-size: 1.5rem;
	letter-spacing: 0.06rem;
	line-height: 36px;
	text-align: justify;
}
</style>
