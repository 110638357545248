<template>
	<menu class="menu-frame" :class="$route.query['open-menu'] ? 'menu-frame--opened' : ''" id="menu-frame">
		<div class="menu-frame__inner">
			<div class="two-column">
				<div class="two-column__column">
					<a class="link">
						<router-link to="/shop" class="">
							<span class="link--menu link--outer accent--gradient">Shop</span>
						</router-link>
					</a>

					<a class="link" href="/shop/longevity-coin">
						<span class="link--menu link--outer accent--gradient">Coin</span>
					</a>
					<!-- <a class="link" href="https://www.longevitycoin.org">
						<span class="link--menu link--outer accent--gradient">Coin</span>
					</a> -->
					<button class="link" @click="navigateToLeaflet">
						<span class="link--menu link--outer accent--gradient">leaflet</span>
					</button>
					<button class="link" @click="navigateToGame">
						<span class="link--menu link--outer accent--gradient">Game</span>
					</button>
					<!-- <a class="link" href="https://LongevityGame.org">
						<span class="link--menu link--outer accent--gradient">Game</span>
					</a> -->
					<a class="link" href="https://LongevityPetition.com">
						<span class="link--menu link--outer accent--gradient">Petition</span>
					</a> -->
					<!-- <a class="link" href="https://longevityscientificresort.com/">
						<span class="link--menu link--outer accent--gradient">Resort</span>
					</a> -->
					<a class="link" href="https://t.me/LongevityInTime">
						<span class="link--menu link--outer accent--gradient">Blog</span>
					</a>
				</div>

				<div class="two-column__column">
					<a class="link" href="https://m.imdb.com/name/nm6088870/">
						<span class="link--menu link--outer accent--gradient">Hollywood producer <br />behind this company</span>
					</a>
					<router-link to="/tos" class="link link--menu link--outer accent--gradient link--outer indigo">
						Terms of use
					</router-link>

					<router-link to="/tos" class="link link--menu link--outer accent--gradient link--outer indigo">
						privacy policy
					</router-link>

					<router-link to="/progress" class="link link--menu link--outer accent--gradient link--outer indigo">
						current progress
					</router-link>
				</div>
			</div>

			<div class="menu-frame__footer">
				<div class="app-buttons">
					<a
						href="https://play.google.com/store/apps/details?id=com.intime.diseaseTrackerPro"
						class="app-buttons__button"
					>
						<img src="../assets/img/svg/google.svg" />
					</a>
				</div>

				<div class="flex contact-links">
					<div class="contact-links__buttons flex">
						<a href="mailto:contact@longevityintime.org" class="contact-links__email">
							<img src="../assets/img/svg/envelope.svg" />
							<span>contact@longevityintime.org</span>
						</a>
					</div>

					<div class="social-links flex">
						<a href="https://twitter.com/TETERINOLEG"> <img src="../assets/img/social-icons/twitter.svg" /></a>

						<a href="https://www.facebook.com/share/2rSnbRv93hv36rR4/?mibextid=LQQJ4d">
							<img src="../assets/img/social-icons//facebook.svg"
						/></a>

						<a href="https://www.linkedin.com/company/longevityintime/">
							<img src="../assets/img/social-icons/linked_in.svg"
						/></a>

						<a href="https://www.instagram.com/teterinolegjr/">
							<img src="../assets/img/social-icons/instagram.svg"
						/></a>

						<a href="https://t.me/LongevityInTime"> <img src="../assets/img/social-icons/telegram.svg" /></a>
					</div>
				</div>
			</div>
		</div>
	</menu>
</template>

<script>
export default {
	name: "MenuModal",
	methods: {
		navigateToLeaflet() {
			this.$router.push("/leaflet");
		},
		navigateToGame() {
			this.$router.push("/menu-game");
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../colors.scss";
@import "../mixins.scss";

.menu-frame {
	position: absolute;
	transform: scale(0.7);
	transform-origin: 0 0;
	width: 145vw;
	height: calc(135vh - 84px);
	overflow: hidden;
	background-color: #fff;
	top: -200%;
	left: 0;
	padding-top: 20px;
	transition: all 0.3s ease-in-out;

	&:after {
		content: "";
		display: block;
		width: 125vw;
		position: absolute;
		top: 15%;
		left: -5%;
		background-image: url("../assets/img/svg/back_pattern.svg");
		background-repeat: no-repeat;
		background-size: 100%;
	}

	@include mobile {
		padding-bottom: 15%;
	}

	&__inner {
		padding: 0% 1.7%;
	}

	&__footer {
		display: flex;
		justify-content: space-between;

		@include mobile {
			flex-direction: column;
		}
		.contact-links {
			flex-basis: 44%;
			display: flex;
			flex-direction: column;
			margin: 0px;
			align-items: center;

			&__buttons {
				@include mobile {
					flex-direction: column;
					align-items: center;
				}
			}

			.social-links {
				margin-top: 48px;
			}
		}
	}

	&--opened {
		z-index: 40;
		overflow: auto;
		top: 100px;
		position: fixed;
		transition: all 0.3s ease-in-out;
	}

	.two-column {
		margin-bottom: 0;

		&__column {
			z-index: 41;
		}
	}

	.button--close {
		right: 7.8%;
		z-index: 42;
	}

	.link {
		margin-bottom: 5%;
		cursor: pointer;
		&__text {
			font-weight: 400;
			font-size: 20px;
			line-height: 26px;
			max-width: 70%;
			text-transform: capitalize;

			@include mobile {
				font-size: 16px;
				line-height: 21px;
				max-width: 100%;
			}
		}

		span {
			display: block;
		}
	}

	.submenu-links-list {
		list-style: none;
		padding-left: 30px;
		margin-bottom: 48px;
		border-left: 3px solid $accent;
		margin-top: -50px;

		li {
			margin-bottom: 24px;
			&:last-child {
				margin: 0;
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.menu-frame {
		top: 50px;
	}
}
</style>
