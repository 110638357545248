<template>
	<section class="game">
		<div class="game__buttons">			
			<button class="game__button" @click="navigateToGameAbout">
				<p class="game__button-link">About</p>
			</button>
			<button class="game__button" @click="navigateToMiniApp">
				<p class="game__button-link">Longevity Coin: Mini Game on TON</p>
			</button>
			<button class="game__button">
				<a
					class="game__button-link"
					href="https://www.figma.com/proto/HOPacHKVWoCf4Pp0ggHDEr/Longevity-Coin?node-id=1-2&node-type=frame&t=8Lp6z5CIsOSFDR7t-0&scaling=scale-down&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=1%3A2&show-proto-sidebar=1"
					target="_blank"
				>
					Game flow simulation
				</a>
			</button>
			<button class="game__button">
				<a
					class="game__button-link"
					href="https://docs.google.com/file/d/1eSkAMib5ntYm7WeLn86EGwGbuxUdOUmq/edit?usp=docslist_api&filetype=mspresentation"
					target="_blank"
				>
					For Investors
				</a>
			</button>
			<button class="game__button">
				<a class="game__button-link" href="https://t.me/longevity_mini_app_bot/longcoin" target="_blank">
					Game Bot Prototype
				</a>
			</button>
			<button class="game__button">
				<a class="game__button-link" href="https://t.me/LongevityCoinGroup" target="_blank">
					Join Game Community
				</a>
			</button>
			<button class="game__button">
				<a class="game__button-link" href="https://youtu.be/D_hZcHNl-9A?si=VwArvGaI370MokQx" target="_blank">
					Previous Game Concept
				</a>
			</button>
		</div>
	</section>
</template>

<script>
export default {
	name: "MenuGame",
	methods: {
		navigateToMiniApp() {
			this.$router.push("/mini-app");
		},
		navigateToGameAbout() {
			this.$router.push("/game-about");
		},
	},
};
</script>

<style lang="scss" scoped>
.game {
	background-image: url(../assets/img/svg/back_pattern.svg);
	background-size: cover;
	font-style: normal;
	font-weight: 400;
	font-family: "Titillium Web";
	line-height: 2.8;
}

.game__buttons {
	width: 100%;
	box-sizing: border-box;
	margin: 0;
	padding: 150px 10%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 15%;
}
.game__button {
	padding: 20px;
	border: 1px solid #3683fc;
	border-radius: 15px;
	width: 50%;
	background-clip: text;
	cursor: pointer;
	margin: 10px auto;
	box-sizing: border-box;
}

.game__button-link {
	text-decoration: none;
	font-family: "Titillium Web";
	font-size: calc(16px + 1vw);
	color: #3683fc;
	margin: 0;
	font-style: normal;
	font-weight: 400;
}

@media screen and (max-width: 600px) {
	.game__buttons{
		padding-top: 60px;
		padding-bottom: 60px;
	}
}
</style>
