<template>
	<header class="header" :class="$store.state.mainFrame && !$route.query['open-menu']
		? 'header--main'
		: 'header--second'
		">
		<div class="header-inner">
			<div class="flex flex--align-center">
				<div v-if="!$route.query['open-menu'] && $route.name !== 'lp'" class="button-back"
					@click.prevent="goBack()">
					<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
						<path fill-rule="evenodd" clip-rule="evenodd"
							d="M17.0712 23.839L9.11624 15.884C8.62809 15.3959 8.62809 14.6044 9.11624 14.1162L17.0712 6.16129C17.5593 5.67313 18.3508 5.67313 18.839 6.16129C19.3271 6.64944 19.3271 7.4409 18.839 7.92906L12.5001 14.2679L12.5001 15.7324L18.839 22.0712C19.3271 22.5593 19.3271 23.3508 18.839 23.839C18.3508 24.3271 17.5593 24.3271 17.0712 23.839Z"
							fill="currentColor" />
					</svg>
				</div>
				<router-link to="/" @click="switchMainFrame">
					<div class="logo">
						<img src="../assets/logo/Logo512x256.svg" class="logo__img" alt="logo" />
						<p class="logo__title" v-if="!$store.state.isMobile">
							<span>Longevity</span>
							<span>InTime</span>
						</p>
					</div>
				</router-link> 
				<nav v-show="(!$store.state.mainFrame && $route.name === 'lp') ||
					!$store.state.mainFrame
					">
					<HeaderMenu :data="$route.meta.menuHeader || [
						{ url: '', label: 'App', hash: '#aboutApp' },
						{ url: '', label: 'About', hash: '#about' },
						{ url: '', label: 'Team', hash: '#team' },
						{ url: '', label: 'VERTICALS', hash: '#ecosystem' },
						{ url: '', label: 'CONTACT', hash: '#' },
					]
						" />
				</nav>
			</div>
			<nav class="nav header__control flex">
				updating website works in progress &nbsp;&nbsp;
				<button class="button button--switch" :class="$route.query['open-menu'] ? 'button--close' : 'button--menu'"
					type="menu" id="open-menu-button" @click="switchMenuStateButton"></button>
			</nav>
		</div>
		
	</header>
</template>

<script>
import Badge from "@/components/Badge/Badge.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import { scrollCheck } from "@/utils/scrollChecker"
export default {
	name: "Header",
	props: ["menuState"],
	components: { Badge, HeaderMenu },
	data() {
		return {
		};
	},
	mounted() {
	},
	computed: {
		async user() {
			return false

		},
		cartCount() {
			return Object.keys(this.$store?.state.cart).length
		},
		isShop() {
			return (
				this.$route.path.startsWith("/shop") ||
				this.$route.path.startsWith("/products")
			);
		},
	},
	methods: {
		switchMenuStateButton() {
			this.$router.push({ query: { 'open-menu': this.$route.query['open-menu'] === "true" ? undefined : true } })
			scrollCheck(this.$route.query, this.$store.state.isMobile)
		},
		goBack() {
			if (this.$router.options.history.state.back) {
				this.$router.go(-1);
			} else {
				this.$router.push("/");
			}
		},
		openLangBox() {
			this.$router.push({ query: { ...this.$route.query, 'open-lang': true } })
		},
		switchMainFrame() {
			this.$store.commit("switchMainFrame", !this.$store.state.mainFrame);
		},
		switchOpenAuth() {
			this.$router.push({ name: 'login', path: `@/components/Auth/login.vue`, params: { handle: "product.handle" } })
		},
			cartgo() {
				this.$router.push("/cart")
			},
		},
};
</script>

<style lang="scss" scoped>
@import "../colors.scss";
@import "../mixins.scss";

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "DM Sans";
}

body {
	margin: 0px !important;
}

.block {
	display: block;
}

.flex {
	display: flex;

	&--align-center {
		align-items: center;
	}
}

.accent {
	color: $accent;

	&--gradient {
		background: linear-gradient(105.24deg, #3683fc -28.95%, #21355c 260.08%);
		background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}

.dark-blue {
	color: $maastricht-blue;
}

.light-blue {
	color: $light-blue;
}

.indigo {
	color: $indigo;
}

.grey2 {
	color: $grey2;
}

.header {
	width: 125vw;
	padding: 40px 36px;
	z-index: 42;
	position: fixed;
	transform: scale(0.8);
	transform-origin: 0 0;
	top: 0;
	background-color: #fff;
	display: block;

	@include mobile {
		height: max-content;
		width: 125vw;
		padding: 10px 3vw;
		padding-top: 10px;
	}

	.header-inner {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__menu {
		@include mobile {
			display: flex;
			position: fixed;
			top: 113vh;
			right: 0;
			background: #cde0fe;
			box-shadow: 6px 6px 48px rgba(12, 30, 60, 0.1);
			border-radius: 120px 0 0 120px;
			padding: 8px 24px;
			height: 48px;
		}
	}

	&--main {

		.logo__title {
			display: flex;

			@include mobile {
				display: none;
			}
		}

		.header__menu {
			display: none;

			@include mobile {
				display: flex;
				height: 48px;
				width: 620px;
				overflow-x: scroll;
				overflow-y: hidden;
			}
		}

		.header__menu::-webkit-scrollbar {
			display: none;
		}
	}

	&--second {
		.header-inner {
			@include mobile {
				flex-flow: wrap;
			}
		}

		.logo__title {
			display: none;

			@include mobile {
				display: flex;
			}
		}

		.header__menu {
			display: none;
		}

		.header__control {
			align-items: center;
			font-style: normal;
			font-weight: 400;
			font-family: "Titillium Web";
			font-size: calc(7px + 1vw);
			line-height: 1;

			@include mobile {
				// flex-basis: 100%;
				justify-content: space-between;
			}
		}
	}

	.logo {
		display: flex;

		&__img {
			width: 128px;
			height: 63px;

			@include mobile {
				width: 96px;
				height: 47px;
			}
		}

		&__title {
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			margin-left: 24px;
			font-size: 24px;
			line-height: 29px;

			&.hide {
				display: none;
			}

			@media (max-width: 400px) {
				display: none;
			}

			span {

				@include mobile {
					font-weight: 400;
					font-size: 20px;
					line-height: 24px;
				}

				&:last-child {
					color: $accent;
				}
			}
		}
	}
}

.header__control {
	align-items: center;
}

.cart {
	justify-content: space-between;
	align-items: center;
	display: flex;
	width: 114px;
	height: 48px;
	font-size: 24px;
	color: var(--masstrich-blue);
}

.nav .button {
	line-height: 31px;
	text-transform: uppercase;
	min-width: auto;

	&-shop {
		text-transform: none;

		&-active {
			color: $accent;
			cursor: default;
		}
	}

	@include mobile {
	}

	&:last-child {
		margin-right: 0;
	}
}

.button-back {
	display: flex;
	padding: 15px;
	align-items: center;
	margin-right: 20px;
	border-radius: 50px;
	color: $masstrich-blue;
	background: var(--background, rgba(12, 30, 60, 0.1));
	cursor: pointer;
}

.button,
button {
	border: none;
	background-color: transparent;
	cursor: pointer;
	display: block;
	padding: auto;

	&--switch {
		margin-left: auto;
		margin-right: 20px;
	}

	&--main {
		font-size: 48px;
		line-height: 62px;
		color: $indigo;
		text-transform: capitalize;
	}

	&--menu {
		width: 31px;
		height: 31px;
		background-image: url("../assets/img/svg/menu-icon.svg");
		background-repeat: no-repeat;
		background-size: 90% 90%;
		min-width: 3rem;
		min-height: 2rem;
		padding: 0;
		border-radius: 0;
		min-width: 31px;
		margin-right: 20px;

		@include mobile {
			min-width: 24px;
			height: 20px;
			margin-left: 10px;
			margin-right: 0;
			border-radius: 0;
		}
	}

	&--auth {
		padding: 0 10px;

		@include mobile {
			margin-right: 0 !important;
			padding: 0 12px;
		}
	}

	&--close {
		width: 31px;
		height: 31px;
		background-image: url("../assets/img/svg/close-icon.svg");
		background-repeat: no-repeat;
		background-size: contain;
		min-width: 31px;
		// margin-left: 10px;
		padding: 0;
	}

	&--lang {
		// display: none;
		display: flex;
		align-items: center;
		color: $grey4;
		padding: 0 10px;

		&::after {
			content: "";
			display: block;
			width: 24px;
			height: 24px;
			background-image: url("../assets/img/svg/chevron_down.svg");
			background-repeat: no-repeat;
			margin-left: 9px;
		}

		@include mobile {
			padding: 0;
		}
	}
}

.header__menu {
	display: flex;
	padding: 15px 0;
	margin: 0 15px;
	align-items: center;
	width: calc(122vw - 490px);
	overflow-x: scroll;
	overflow-y: hidden;
	padding-bottom: 0;

	&::-webkit-scrollbar {
		background-color: transparent;
		height: 10px;
		margin-left: 20px;

		&-corner {
			background-color: transparent;
			border-color: transparent;
		}

		&-thumb {
			border-radius: 10px;
			background-color: $grey0;
		}

		&-track {
			margin-left: 20px;
		}
	}

	@include mobile {
		padding: 20px 0;
		width: calc(129vw - 80px);
	}

	a {
		color: $indigo;

		&:active,
		&:hover {
			color: $accent;
		}
	}
}
</style>
